body {
  background-color: rgb(239, 239, 237);
  font-family: sans-serif;
  margin: 0 0 1vmin 0;
  white-space: pre-line;
}
.clear {
  clear: both;
}
.content-padding {
  height: 64px;
}
.card {
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 0.550087px 2.4261px,
    rgba(0, 0, 0, 0.24) 0px 1.01372px 2.32903px;
  border-radius: 6px;
  color: black;
  margin: 2vmin auto 0 auto;
  max-width: 90vmin;
  padding: 3vmin;
  width: 801px;
}
.header-container {
  position: absolute;
  top: 100%;
  width: 100%;
  >.header {
    line-height: 66px;
    margin: 0 auto 0 auto;
    max-width: 90vmin;
    width: calc(801px + 4vmin);
    >span {
      font-size: 28px;
      &.clickable {
        cursor: pointer;
        font-family: 'Poiret One', sans-serif;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.button {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid white;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 4vmin;
  height: 8vmin;
  padding-left: 1.5vmin;
  transition: background-color 0.2s, color 0.2s;
  user-select: none;
  width: 26vmin;
  >span, >svg {
    font-size: 3.9vmin;
    margin-right: 1vmin;
  }
  >span {
    padding-bottom: 2px;
  }
  &:hover {
    background-color: white;
    color: rgb(64, 64, 64);
  }
}
.cover {
  background: linear-gradient(140deg, #bb63ff 0%, #6712a8 80%);
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 64px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transition: transform 0.5s;
  &.lifted {
    transform: translateY(calc(-100% + 64px))
  }
  >.landing {
    height: calc(100% - 64px);
    position: relative;
    >.title-container {
      font-family: 'Poiret One', 'Helvetica Nene', Helvetica, Arial, sans-serif;
      position: absolute;
      left: 6vmin;
      bottom: 8vmin;
      user-select: none;
      h1 {
        font-size: 22vmin;
        font-weight: 10;
        margin: -2vmin 0 -2.8vmin 0;
      }
      h2 {
        font-size: 6.2vmin;
        font-weight: 10;
        margin: 0;
        letter-spacing: 0.4em;
        padding-left: 0.2em;
      }
    }
    >.privacy-notice {
      font-size: 1.6vmin;
      color: #dddddf;
      position: absolute;
      bottom: 0;
      left: 6vmin;
      width: calc(100% - 9vmin);
      height: 5.5vmin;
    }
    >.button-panel {
      position: absolute;
      left: 41vmin;
      bottom: 8vmin;
      height: 26vmin;
      >.button {
        position: absolute;
        &.start {
          left: 0;
          top: 0;
        }
        &.compare {
          left: 0;
          top: 9vmin;
        }
        &.language {
          left: 0;
          top: 18vmin;
        }
        &.reset {
          left: 27vmin;
          top: 0;
        }
        &.backup {
          left: 27vmin;
          top: 9vmin;
        }
        &.about {
          left: 27vmin;
          top: 18vmin;
        }
      }
    }
  }
}

